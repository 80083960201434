import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'account-not-found',
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.less',
  standalone: true,
  imports: [TranslateModule],
})
export class NotFoundComponent {}
